import React, { useState, useEffect } from 'react';
import './App.css';
import techorTreeLogo from './techortreelogo.png';


// Define the extractColorsFromImage function
async function extractColorsFromImage(imageUrl) {
  // Your logic for extracting colors goes here
  // This function should return the dominant color of the image
  // For demonstration purposes, let's assume it returns a color immediately
  return '#004da2'; // Default color
}

function App() {
  const [dateTime, setDateTime] = useState(new Date());
  const [logoColor, setLogoColor] = useState('#004da2'); // Default color

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // Call the extractColorsFromImage function to get the logo color
    extractColorsFromImage(techorTreeLogo).then(color => {
      setLogoColor(color);
    });
  }, []);

  const formatDate = (date) => {
    const day = date.getDate();
    let suffix = '';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }
  
    return `${day}${suffix} ${date.toLocaleDateString('en-US', { month: 'long' })}, ${date.getFullYear()}`;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={techorTreeLogo} alt="TechorTree Logo" className="logo-preview" />
        </div>
        <div className="spacer"></div>
    
        <div className="welcome-message">
          <h1>Welcome to Tech or Tree</h1>
          <h1>Coming Soon!</h1>
        </div>
        <div className="date-time">
          {formatDate(dateTime)}
        </div>
        
      </header>
    </div>
  );
}

export default App;
